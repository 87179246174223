/* You can add global styles to this file, and also import other style files */
@import "/src/styles/_colors";


html, body{
    margin: 0;
    font-family: 'Cabin';
}

p{
    line-height: 1.5 !important;
}


.p-placeholder{
    color: black !important;
  }
